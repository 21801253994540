import { LinkType } from '../models/LinkType';
import './Link.css';

const Link = ({ href, text, special }: LinkType) => {
  return (
    <a
      href={href ?? '#'}
      className={special ? 'navbar-special-link' : 'navbar-link'}>
      {text}
    </a>
  );
};

export default Link;
