import './Image.css';
import dotsIcon from '../assets/dots-image.svg';
import { ImageType } from '../models/ImageType';

const Image = ({ image }: ImageType) => {

  return (
    <div
      className='image-wrapper'
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/${image}.png)`,
      }}>
      <img className='image-icon' src={dotsIcon} />
    </div>
  );
};

export default Image;
