import Content from '../../components/Content';

const WhoAreWe = () => {
  return (
    <div className='whoAreWe-wrapper'>
      <h1>Kim jesteśmy?</h1>
      <Content>
        vPET to jedyny i najlepszy serwer o tematyce truck, który pozwoli
        wcielić Ci się w rolę nie tylko kierowcy ciężarówki, ale też daje
        możliwość wcielenia się w pracownika służb porządkowych. Od 2019 roku
        zapewniamy rozrywkę dla fanów pojazdów ciężarowych na platformie FiveM.
        Przez cały ten okres serwer miał zaszczyt gościć tysiące graczy
        spragnionych życia kierowcy ciężarówki. Zapraszamy do zapoznania się z
        ofertą oraz sprawdzenia serwera w praktyce!
      </Content>
    </div>
  );
};

export default WhoAreWe;
