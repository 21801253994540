import Content from '../../components/Content';
import GridItems from '../../components/GridItems';
import Image from '../../components/Image';
import Article from '../../components/Article';

const HowDoesTheGameLookLike = () => {
  return (
    <div>
      <h1>Jak wygląda rozgrywka ?</h1>
      <GridItems>
        <Content>
          Jak to już bywa początek rozgrywki jest trudny. Jako nowi członkowie
          społeczności serwerowej, będziecie mieć dostęp do podstawowych
          czynności. Na samym początku możecie wsiąść w ciężarówkę i rozpocząć
          pracę typowego kierowcy. Wszak jak pokonywanie kilometrów z
          załadowanymi naczepami troszkę przynudza to można spróbować innych
          aktywności na serwerze!.
        </Content>
        <Image image='image_1' />
      </GridItems>
      <Article image='image_2' title='KURIER'>
        Dzięki tym pracom zdobędziesz wymagane doświadczenie, poznasz serwer od
        środka i będziesz mógł cieszyć się z gry w naszej społeczności. Po
        zapoznaniu się z zasadami rozgrywki masz kilka opcji! Możesz pozostać
        wolnym strzelcem jako trucker, postarać się o przyjęcie do jednej z
        zarejestrowanych firm na serwerze, bądź też spróbować swoich sił w
        jednej z dwóch frakcji.
      </Article>
      <Article image='image_3' title='KIEROWCA MZK'>
        Jeżeli znudzi Ci się praca jako szara myszka w firmie/frakcji, możesz
        założyć własną działalność lub odkupić jedną z zarejestrowanych firm.
        Będąc szefem firmy masz dostęp do specjalnego panelu, w którym zobaczysz
        ile kursów zrobili pracownicy, jakie wygenerowali zarobki, ile
        kilometrów przejechali. Pamiętaj! Jako szef masz obowiązek opłacać
        podatki także zastanów się czy chcesz prowadzić firmę czy być zwykłym
        pracownikiem!
      </Article>
    </div>
  );
};

export default HowDoesTheGameLookLike;
