import Article from '../../components/Article';
import Button from '../../components/Button';
import Content from '../../components/Content';
import DiscordButton from '../../assets/discord-icon.svg';
import './Summary.css';

const Summary = () => {
  return (
    <div className='summary-wrapper'>
      <Article image='summary' />
      <Content>
        Więcej nie będziemy zdradzać. Odwiedź nasz serwer i przekonaj się co
        jeszcze mamy do zaoferowania. Jeżeli kręci Cię tematyka ciężarówek,
        kochasz Euro Truck Simulator i masz ochotę poznać coś nowego, nie
        będziesz rozczarowany. Klimat jaki u nas panuje przekona Cię do
        pozostania z nami na dłużej!
      </Content>
      <Button
        link='https://discord.vpet.pl/'
        img={DiscordButton}
        text='DISCORD'
        color='#36a4dd'
      />
    </div>
  );
};

export default Summary;
