import { ButtonType } from '../models/ButtonType';
import './Button.css';

const Button = ({ link, text, color, img }: ButtonType) => {
  return (
    <a href={link} className='main-button' style={{ backgroundColor: color }}>
      <img src={img} alt="" />
      {text}
    </a>
  );
};

export default Button;
