export const fetchData = <TData>(
  url: string,
  onSuccess: (data: TData) => void,
  onErr: (err: string) => void
) => {
  fetch(url)
    .then(res => res.json())
    .then(data => onSuccess(data as TData))
    .catch(err => onErr(err));
};
