import discord from '../../assets/discord-icon.svg';
import facebook from '../../assets/facebook-icon.svg';
import patronite from '../../assets/patronite-icon.svg';
import tiktok from '../../assets/tiktok-icon.svg';
import youtube from '../../assets/youtube-icon.svg';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer-wrapper'>
      <div className='footer-text'>Zapraszamy do obserwowania!</div>
      <div className='footer-icons'>
        <a href='https://www.facebook.com/vPETpl'>
          <img src={facebook} alt='fb' />
        </a>
        <a href='https://www.youtube.com/@vpet3326'>
          <img src={youtube} alt='yt' />
        </a>
        <a href='https://discord.vpet.pl/'>
          <img src={discord} alt='dc' />
        </a>
        <a href='https://www.tiktok.com/@vpetpl'>
          <img src={tiktok} alt='tt' />
        </a>
        <a href='https://patronite.pl/vPET.pl'>
          <img src={patronite} alt='pt' />
        </a>
      </div>
      <div className='footer-privacy'>All rights reserved by vPET</div>
    </div>
  );
};

export default Footer;
