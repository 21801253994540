import CountUp from 'react-countup';
import { StatType } from '../../models/StatTypes';
import './Stat.css';

const Stat = ({ value, icon, title, desc, special }: StatType) => {
  const number = value ?? 0;
  const duration = number < 5000 ? 3 : 5;

  return (
    <div className='stat-wrapper'>
      <img className='stat-image' src={icon} alt='' />
      <CountUp
        className={!special ? 'stat-number' : 'stat-special-number'}
        enableScrollSpy
        duration={duration}
        end={number}
      />
      <div className='stat-title'>{title}</div>
      <div className='stat-desc'>{desc}</div>
    </div>
  );
};

export default Stat;
