import Button from '../../components/Button';
import DiscordButton from '../../assets/discord-icon.svg';
import PanelButton from '../../assets/panel-icon.svg';
import './Main.css';

const Main = () => {
  return (
    <div
      className='main-wrapper'
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/main-image.png)`,
      }}>
      <div className='main-content'>
        <div className='main-logo'>vPET.pl</div>
        <div className='main-description'>
          Jedyny i najlepszy serwer o tematyce truck, który pozwoli wcielić Ci
          się w rolę nie tylko kierowcy ciężarówki, ale też daje możliwość
          wcielenia się w pracownika służb porządkowych.
        </div>
        <div className='main-buttons'>
          <Button
            link='https://discord.vpet.pl/'
            img={DiscordButton}
            text='DISCORD'
            color='#36a4dd'
          />
          <Button
            link='https://panel.vpet.pl/'
            img={PanelButton}
            text='PANEL'
            color='#ed801c'
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
