import { useState } from 'react';
import ProgressBar from 'react-progressbar-on-scroll';
import burgerMenu from '../../assets/burger-menu.svg';
import closeMenu from '../../assets/close-menu.svg';
import Link from '../../components/Link';
import './Navbar.css';

const Navbar = () => {
  console.log();
  const [burger, burgerSet] = useState(false);

  return (
    <div className='navbar-position'>
      <div className='navbar-wrapper'>
        <div className='navbar-empty'></div>
        <a href='#'>
          <img
            className='navbar-logo'
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt='Logo'
          />
        </a>
        <div className='navbar-links'>
          <Link text='Strona główna' />
          <Link text='Panel' href='https://panel.vpet.pl/' />
          <Link text='Discord' href='https://discord.vpet.pl/' />
          <Link
            text='Patronite'
            href='https://patronite.pl/vPET.pl'
            special={true}
          />
        </div>
        <img
          onClick={() => burgerSet(!burger)}
          className='navbar-burger'
          src={!burger ? burgerMenu : closeMenu}
          alt='Burger Menu'
        />
      </div>
      {burger ? (
        <div className='navbar-responsive-links'>
          <Link text='Strona główna' />
          <Link text='Panel' href='https://panel.vpet.pl/' />
          <Link text='Discord' href='https://discord.vpet.pl/' />
          <Link
            text='Patronite'
            href='https://patronite.pl/vPET.pl'
            special={true}
          />
        </div>
      ) : null}
      <span className='navbar-progressbar'>
        <ProgressBar height={2} position='bottom' color='#36a4dd' />
      </span>
    </div>
  );
};

export default Navbar;
