import Article from '../../components/Article';

const Fractions = () => {
  return (
    <div className='fractions-wrapper'>
      <h1>JAKIE MAMY FRAKCJE ?</h1>
      <Article title='POLICJA' image='police'>
        Waszą główną misją w tej frakcji jest pilnowanie innych uczestników w
        ruchu drogowym. Mierzenie prędkości, sprawdzanie masy pojazdów,
        inspekcji przewożonego towaru oraz reagowanie na wykroczenia drogowe.
      </Article>
      <Article title='POMOC DROGOWA' image='pd'>
        Jak sama nazwa mówi, Waszym zadaniem będzie sprawna naprawa pojazdów
        innych graczy, dowożenie zapominalskim graczom paliwa, a czasami też
        holowanie porzuconych bądź zepsutych pojazdów. System zgłoszeń jest dość
        prosty. Otrzymujecie zgłoszenie, udajecie się na miejsce zgłoszenia i
        wykonujecie usługę, o którą zostaliście poproszeni.
      </Article>
    </div>
  );
};

export default Fractions;
