import Content from '../../components/Content';
import GridItems from '../../components/GridItems';
import Image from '../../components/Image';
import './Systems.css';

const Systems = () => {
  return (
    <div className='systems-wrapper'>
      <h1>SYSTEMY</h1>
      <div className='systems-content'>
      <GridItems>
          <Image image={'xp'} />
          <Content>
          System polegający na zdobywaniu punktów doświadczenia za wykonywane czynności na serwerze. Im wyższy poziom postaci tym więcej otrzymujemy pieniędzy za dostway.
          </Content>
        </GridItems>
        <GridItems>
          <Content>
            Jakże inaczej... bez paliwa daleko nie dojedziecie. Każdy z pojazdów
            ma różne spalanie, więc trzeba być ostrożnym i bacznie obserwować
            stan naszego baku, a w razie potrzeby uzupełnić paliwo na jednej z
            dostępnych stacji. Ceny na naszych stacjach są różne, w zależności
            od lokalizacji i stanu napełnienia.
          </Content>
          <Image image={'fuel'} />
        </GridItems>
        <GridItems>
          <Image image={'sleep'} />
          <Content>
            Każdy kierowca kiedyś się zmęczy, dlatego musicie uważać na poziom
            zmęczenia. Jeżeli Wasz poziom zmęczenia będzie wynosił 100%
            będziecie zasypiać za kierownicą tworząc tym samym zagrożenie w
            ruchu drogowym. Zmęczenie można zredukować na kilka sposobów. Jednym
            z tych sposobów jest zakup i wypicie napoju ze sklepu, zatrzymanie
            się na specjalnie wyznaczonym parkingu i przespanie się, a dla osób
            posiadających własne nieruchomości możliwość znajduje się tuż przy
            łóżku w domu.
          </Content>
        </GridItems>
        
        <GridItems>
          <Content>
          Prosty system symulujący głód naszej postaci. Głód rośnie w każdej minucie na serwerze. W przypadku kiedy będziemy głodni zaczniemy tracić HP, a tym samym możemy umrzeć. Głód oczywiście zaspokajamy jedząc przekąski z sklepu.
          </Content>
          <Image image={'food'} />
        </GridItems>
       
        <GridItems>
          <Image image={'house'} />
          <Content>
          Zarobiłeś swoje pierwsze kilkaset tysięcy dolarów? To idealny moment na zakup własnych czterech ścian. W domu można zaspokoić głód, porządnie się wyspać czy też zaparkować prywatne auto pod domem.
          </Content>
        </GridItems>
      </div>
    </div>
  );
};

export default Systems;
