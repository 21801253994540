import Content from '../../components/Content';
import GridItems from '../../components/GridItems';
import Image from '../../components/Image';

const Panel = () => {
  return (
    <div>
      <h1>PANEL GRACZA</h1>
      <GridItems>
        <Content>
          Na serwerze odnajdziesz bardzo innowacyjny panel, który jest dostępny
          z poziomu przeglądarki. Większość rzeczy jak przykładowo zarządzanie
          firmą albo frakcją wykonasz będąc tysiące kilometrów od komputera. Do
          tego z poziomu panelu możesz zarządzać swoją bankowością, pojazdami
          prywatnymi, swoimi nieruchomościami oraz sprawdzić rankingi.
        </Content>
        <Image image='panel' />
      </GridItems>
    </div>
  );
};

export default Panel;
