import './App.css';
import Navbar from './modules/navbar/Navbar';
import Main from './modules/main/Main';
import Stats from './modules/stats/Stats';
import WhoAreWe from './modules/whoAreWe/WhoAreWe';
import HowDoesTheGameLookLike from './modules/HowDoesTheGameLookLike/HowDoesTheGameLookLike';
import Fractions from './modules/Fractions/Fractions';
import Panel from './modules/Panel/Panel';
import Summary from './modules/Summary/Summary';
import Systems from './modules/Systems/Systems';
import Footer from './modules/Footer/Footer';

function App() {
  return (
    <div className='app'>
      <Navbar />
      <Main />
      <Stats />
      <WhoAreWe />
      <HowDoesTheGameLookLike />
      <Fractions />
      <Panel />
      <Systems />
      <Summary />
      <Footer />
    </div>
  );
}

export default App;
