import dotsIcon from '../assets/dots-image.svg';
import './Article.css';
import Content from './Content';
import { ArticleType } from '../models/ArticleType';

const Article = ({ image, title, children }: ArticleType) => {
  return (
    <div className='article-wrapper'>
      {title ? <h2>{title}</h2> : null}
      <div
        className='article-image'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/${image}.png)`,
        }}>
        <img className='article-icon' src={dotsIcon} />
      </div>
      {children ? <Content>{children}</Content> : null}
    </div>
  );
};

export default Article;
