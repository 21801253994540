import { ContentType } from '../models/ContentType';
import './Content.css';

const Content = ({ children }: ContentType) => {
  return (
    <div className='text-wrapper'>
      <div className='text-top'>
        <span className='text-top-left' />
        <span className='text-top-down' />
      </div>
      <div className='text-content'>{children}</div>
      <div className='text-bottom'>
        <span className='text-bottom-up' />
        <span className='text-bottom-right' />
      </div>
    </div>
  );
};

export default Content;
