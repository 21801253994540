import Stat from './Stat';
import personIcon from '../../assets/person-stats.svg';
import truckIcon from '../../assets/truck-stats.svg';
import newPeopleIcon from '../../assets/newPeople-stats.svg';
import './Stats.css';
import { useEffect, useState } from 'react';
import { ResModel } from '../../models/ResModel';
import { fetchData } from '../../service/api/fetchData';

const Stats = () => {
  const [stats, setStats] = useState<ResModel>();
  
  useEffect(() => {
    fetchData<ResModel>(
      'https://panel.dev.vpet.pl/index.php/home-page-api/stats',
      data => {
        // console.log(data);
        setStats(data);
      },
      err => console.error(err)
    );
  }, []);

  return (
    <div className='stats-wrapper'>
      <div className='stats-content'>
        <Stat
          value={stats?.routesInLast7Days}
          icon={truckIcon}
          title='Dostarczone ładunki'
          desc='Przez ostatni tydzień'
        />
        <Stat
          value={stats?.allUsers}
          icon={personIcon}
          title='Liczba graczy'
          desc='Od startu serwera'
          special={true}
        />
        <Stat
          value={stats?.newUsersInLast30Days}
          icon={newPeopleIcon}
          title='Nowi gracze'
          desc='w ostatnim miesiącu'
        />
      </div>
    </div>
  );
};

export default Stats;
